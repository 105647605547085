export default class ChannelMenu {
    menuId;
    type;
    name;
    key;
    url;
    mediaId;
    articleId;
    appId;
    appPage;
    extra;
    subMenus;
}
